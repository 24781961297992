<template>
  <CreatePassword />
</template>
<script>
import CreatePassword from "@/components/Auth/CreatePassword";
export default {
  components: {
    CreatePassword,
  },
};
</script>
